import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";
import { SELLER_ORDER_LIST, SELLER_ORDER_STATUS } from "@/graphql/user/seller_order";
import dayjs from "dayjs";
import debounce from "debounce";
import bigDecimal from "js-big-decimal";
export default {
  created: function created() {
    document.title = "\u0E04\u0E33\u0E2A\u0E31\u0E48\u0E07\u0E0B\u0E37\u0E49\u0E2D\u0E02\u0E2D\u0E07\u0E09\u0E31\u0E19 | ".concat(WEBSITE);
  },
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "\u0E21\u0E32\u0E40\u0E1B\u0E47\u0E19\u0E1C\u0E39\u0E49\u0E02\u0E32\u0E22",
      route: "/seller"
    }, {
      title: "\u0E04\u0E33\u0E2A\u0E31\u0E48\u0E07\u0E0B\u0E37\u0E49\u0E2D\u0E02\u0E2D\u0E07\u0E09\u0E31\u0E19"
    }]);
  },
  data: function data() {
    var _this$$route$query$ca = this.$route.query.cat,
        cat = _this$$route$query$ca === void 0 ? "" : _this$$route$query$ca;
    return {
      pageSize: 10,
      page: 1,
      search: "",
      status: cat,
      statusValue: {}
    };
  },
  methods: {
    debounceInput: debounce(function (e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    pushCat: function pushCat(val) {
      var newpath = "".concat(this.$route.path, "?cat=").concat(val);
      this.page = 1;

      if (this.$route.fullPath !== newpath) {
        this.$router.push({
          name: "sale_order_list",
          query: {
            cat: val
          }
        });
      }
    },
    getStatusColor: function getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";

        case 0:
          return "warning";

        case 1:
          return "primary";

        case 2:
          return "warning";

        case 40:
        case 90:
          return "success";

        default:
          return "primary";
      }
    },
    format_datetime: function format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }

      return "-";
    },
    format_number: function format_number(val) {
      var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      return bigDecimal.round(val, decimal);
    },
    refresh: function refresh() {
      this.$apollo.queries.user_seller_order_list.refetch();
      this.$apollo.queries.user_seller_order_status.refetch();
    }
  },
  watch: {
    page: function page() {
      this.$apollo.queries.user_seller_order_status.refetch();
    },
    status: function status() {
      this.$apollo.queries.user_seller_order_status.refetch();
    },
    "$route.query.cat": function $routeQueryCat(id) {
      this.status = id;
    }
  },
  apollo: {
    user_seller_order_list: {
      query: SELLER_ORDER_LIST,
      variables: function variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          status: this.status
        };
      }
    },
    user_seller_order_status: {
      query: SELLER_ORDER_STATUS,
      result: function result(_ref) {
        var loading = _ref.loading,
            data = _ref.data;

        if (!loading && data) {
          var statusValue = {};
          data.user_seller_order_status.records.map(function (x) {
            statusValue[x.status] = x.value;
          });
          this.statusValue = statusValue;
        }
      }
    }
  }
};